<template>
  <NuxtPageDependency>
    <h1 class="sr-only">
      {{ $texts('srLandmarks.breadcrumbNavigation', 'Breadcrumb-Navigation') }}
    </h1>

    <nav
      class="bg-gray-20 border-b border-b-gray-400 -mt-1 relative print:hidden"
      :style="{
        '--breadcrumb-item-height': (links.length > 50 ? 70 : 54) + 'px',
      }"
      :aria-label="$texts('breadcrumbs.breadcrumb', 'Breadcrumb')"
    >
      <div class="container relative">
        <div
          class="relative"
          :class="{ 'breadcrumb-caret before:top-full': links.length }"
        >
          <TransitionGroup
            tag="ol"
            itemscope
            itemtype="http://schema.org/BreadcrumbList"
            class="relative transition-all overflow-hidden"
            enter-active-class="duration-300"
            leave-active-class="duration-300"
            enter-from-class="opacity-0"
            leave-to-class="opacity-0"
            :style="{
              height: `calc(var(--breadcrumb-item-height) * ${visibleBreadcrumbCount})`,
            }"
          >
            <li
              v-for="(link, index) in links"
              v-show="link.isVisible"
              :key="link.title + index"
              itemprop="itemListElement"
              itemscope
              itemtype="http://schema.org/ListItem"
              class="breadcrumb-item relative overflow-hidden"
            >
              <component
                :is="link.tag"
                v-bind="link.props"
                itemprop="item"
                class="breadcrumb-link block py-15 font-medium breadcrumb-caret before:top-0 border-t border-t-gray-400 text-blue-900 hover:text-blue-700 overflow-hidden text-ellipsis"
              >
                <span itemprop="name" class="whitespace-nowrap">
                  {{ link.title }}
                </span>
              </component>
              <meta itemprop="position" :content="(index + 1).toString()" />
            </li>
          </TransitionGroup>
        </div>
      </div>
    </nav>
  </NuxtPageDependency>
</template>

<script lang="ts" setup>
import { NuxtLink } from '#components'

const breadcrumbLinks = useBreadcrumbLinks()

const links = computed(() => {
  return breadcrumbLinks.value.map((link, index) => {
    const { title, url } = link
    const to = url?.path
    return {
      tag: to ? NuxtLink : 'span',
      props: to ? { to } : {},
      title,
      // Items say if they are hidden.
      // In addition we always want to hide the last one.
      // **IMPORTANT**: Don't add additional logic to hide links here.
      // ONLY use the "isHidden" value coming from Drupal! This is determined
      // in the "breadcrumb_is_hidden" data producer in Drupal.
      isVisible: index < breadcrumbLinks.value.length - 1 && !link.isHidden,
    }
  })
})

/**
 * Number of breadcrumb items that are visible.
 */
const visibleBreadcrumbCount = computed(
  () => links.value.filter((v) => v.isVisible).length,
)
</script>

<style lang="postcss">
.breadcrumb-caret:before {
  @apply w-0 h-0 content-[''] left-15 absolute border-x-[7px] border-t-[6px] border-t-gray-400 border-x-transparent border-solid;
}

.breadcrumb-item {
  height: var(--breadcrumb-item-height);
}

.breadcrumb-item:hover ~ .breadcrumb-item .breadcrumb-link {
  @apply text-gray-300;
}

@media (prefers-reduced-motion) {
  .breadcrumbs * {
    @apply !transition-none;
  }
}
</style>
