<template>
  <Teleport to="body">
    <LazyRaeppliRaege v-if="showRain" @done="showRain = false" />
  </Teleport>
</template>

<script lang="ts" setup>
const showRain = ref(false)

const KONAMI = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'c',
  'o',
  'r',
  't',
  'e',
  'g',
  'e',
]

let currentPosition = 0

function onKeyDown(e: KeyboardEvent) {
  const requiredKey = KONAMI[currentPosition]
  if (e.key !== requiredKey) {
    currentPosition = 0
    return
  }
  currentPosition++

  if (currentPosition === KONAMI.length) {
    showRain.value = true
    currentPosition = 0
  }
}

onMounted(() => {
  window.addEventListener('keydown', onKeyDown, {
    passive: true,
  })
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', onKeyDown)
})
</script>
