<template>
  <div>
    <SkipToMainContent />
    <ClientOnly>
      <KonamiRain />
    </ClientOnly>

    <ClientOnly>
      <LazyAdminBar v-if="drupalUser.accessToolbar" :is-editing="isEditing" />
    </ClientOnly>

    <NuxtPageDependency>
      <AppTop />
    </NuxtPageDependency>

    <SectionLogos
      v-if="
        isEnvironmentReport ||
        isMetropolitanConference ||
        isImplementationOfCareInitiative ||
        isTrainingContributionsForNursingCare
      "
      :is-environment-report="isEnvironmentReport"
      :is-metropolitan-conference="isMetropolitanConference"
      :is-implementation-of-care-initiative="isImplementationOfCareInitiative"
      :is-training-contributions-for-nursing-care="
        isTrainingContributionsForNursingCare
      "
    />
    <Breadcrumb />

    <ClientOnly>
      <DrupalMessages v-if="!isEditing" />
    </ClientOnly>

    <div id="main" class="page" role="region" :aria-live="ariaLive">
      <slot />
    </div>

    <NuxtPageDependency>
      <hr
        v-if="activeMenuKey === 'topics' || activeMenuKey === 'organization'"
        class="bg-green-500 h-100 border-none"
      />
    </NuxtPageDependency>

    <AppBottom />

    <ClientOnly>
      <Transition name="banner-sticky" appear>
        <LazyCookieBanner v-if="mustDecide" />
      </Transition>
    </ClientOnly>

    <ClientOnly>
      <Chatbot />
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import KonamiRain from '~/components/RaeppliRaege/KonamiRain.vue'

const route = useRoute()
const drupalUser = useDrupalUser()
const {
  isEnvironmentReport,
  isMetropolitanConference,
  isImplementationOfCareInitiative,
  isTrainingContributionsForNursingCare,
} = useCurrentSite()
const { mustDecide } = useConsentCookie()
const activeMenuKey = useActiveMenuKey()

const isEditing = computed(
  () =>
    !!(route.query.blokkliEditing || route.query.blokkliPreview) &&
    drupalUser.value.accessToolbar,
)

const ariaLive = computed(() => route.meta.ariaLive || 'polite')
</script>

<style lang="postcss">
.page-enter-active,
.page-leave-active {
  @apply transition duration-150;
  @media (prefers-reduced-motion) {
    @apply !transition-none;
  }
}
.page-enter-from,
.page-leave-to {
  @apply opacity-0;
}

.bk-grid-overlay {
  @apply fixed top-0 left-0 w-full z-[99999999] h-full pointer-events-none;
  .container {
    @apply grid grid-cols-12 gap-15 h-full;
    > div {
      @apply h-full  border-l-[0.75px] border-l-gray-800/40 border-r-[0.75px] border-r-gray-800/40;
    }
  }
}

[data-bk-is-muted='true'] {
  @apply outline-red-300 outline-dashed outline-4;
}

.pb-main-canvas {
  @apply bg-white;
}
</style>
