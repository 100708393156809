<template>
  <div v-if="messages.length" class="container grid gap-10 my-20 w-full">
    <button
      v-for="(message, i) in messages"
      :key="i"
      class="p-20 font-medium rounded flex justify-between items-center"
      :class="classes[message.type]"
      @click="removeMessage(i)"
    >
      <div v-html="message.message" />
      <SpriteSymbol class="size-20" name="close" aria-hidden="true" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import type { DrupalMessageType } from '~/composables/useDrupalMessages'

const { messages, removeMessage, clearMessages } = useDrupalMessages()

onRouteChange(clearMessages)

// @TODO: Unify with <Message> component.
const classes: Record<DrupalMessageType, string> = {
  error: 'bg-red-100 text-red-800',
  warning: 'bg-blue-100 text-blue-800',
  status: 'bg-primary-100 text-primary-800',
}
</script>
