<template>
  <div v-if="data?.url" class="fixed bottom-0 left-0 w-full z-alva">
    <div class="container is-wide pb-15 md:pb-20 xl:pb-25">
      <div class="relative">
        <div class="absolute bottom-0 right-0 z-50">
          <button
            class="open-launcher rounded-full border border-gray-100 bg-white shadow-lg size-50 md:size-80 flex items-center justify-center z-10 cursor-pointer p-5 md:p-10 group transition-all duration-200 ease-swing"
            :aria-expanded="isVisible"
            aria-controls="launcher-content"
            :aria-label="
              $texts('alvaLauncher.openLauncher', 'Alva Launcher öffnen')
            "
            :class="{
              'hover:scale-105 active:scale-95': !isVisible,
            }"
            @click.prevent="isVisible = !isVisible"
          >
            <div
              class="bg-gradient-to-b size-full rounded-full transition-all relative from-blue-400 to-blue-600 overflow-hidden"
            >
              <div
                class="bg-blue-500 absolute inset-0 opacity-0 group-hover:opacity-50 transition-all z-10 duration-500"
                :class="{
                  '!opacity-100': isVisible,
                }"
              />
              <SpriteSymbol
                name="alva"
                aria-hidden="true"
                class="size-full text-white transition-all animate-rotate-infinite animation-duration-[4s] relative z-20 animation-state-paused hover:animation-state-running"
              />
            </div>
          </button>
        </div>

        <Transition name="alva">
          <div
            v-if="isVisible"
            class="fixed md:absolute md:-bottom-10 md:-right-10 right-0 w-full md:h-auto inset-y-0 md:top-auto md:w-[460px] bg-gradient-to-b from-blue-600 to-blue-400 md:rounded-[20px] md:rounded-br-[40px] z-40 flex flex-col items-end"
          >
            <div class="md:hidden">
              <button
                class="bg-white/95 p-15 rounded-bl-[40px]"
                :aria-expanded="isVisible"
                aria-controls="launcher-content"
                :aria-label="
                  $texts(
                    'alvaLauncher.closeLauncher',
                    'Alva Launcher schliessen',
                  )
                "
                @click="isVisible = !isVisible"
              >
                <span
                  class="size-40 flex items-center justify-center bg-blue-900 rounded-full text-white"
                >
                  <SpriteSymbol
                    name="close"
                    class="size-20"
                    aria-hidden="true"
                  />
                </span>
              </button>
            </div>
            <div class="p-15 md:p-20 xl:p-25">
              <div class="ck-content is-inverted !text-lg" v-html="data.text" />
              <a :href="data.url" target="_blank" class="button mt-40 is-link">
                {{ $texts('alvaLauncher.tryItOut', 'Ausprobieren') }}
              </a>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const isVisible = ref(false)

const { data } = await useAsyncGraphqlQuery('alvaLauncherData', null, {
  transform: (v) => {
    return {
      url: v.data.globalConfig?.link?.uri?.path,
      text: v.data.globalConfig?.text,
    }
  },
  graphqlCaching: {
    client: true,
  },
})
</script>

<style lang="postcss">
.alva-enter-active,
.alva-leave-active {
  @apply transition-all duration-[600ms] ease-swing origin-bottom-right;

  @media (prefers-reduced-motion) {
    @apply !transition-none;
  }
}

.alva-enter-from,
.alva-leave-to {
  clip-path: rect(
    calc(100% - 50px) calc(100% - 20px) calc(100% - 20px) calc(100% - 50px)
      round 120px
  );
  transform: translate(-20px, -20px) scale(0.3);
  @screen md {
    clip-path: rect(
      calc(100% - 90px) calc(100% - 10px) calc(100% - 10px) calc(100% - 90px)
        round 90px
    );
    transform: translate(-15px, -10px) scale(0.5);
  }
}

.alva-leave-from,
.alva-enter-to {
  clip-path: rect(0px 100% 100% 0px round 0px);

  @screen md {
    clip-path: rect(0px 100% 100% 0px round 20px);
  }
}
</style>
