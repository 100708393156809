<template>
  <ChatbotAlva v-if="showAlva" />
</template>

<script setup lang="ts">
const { activeChatbot } = useChatbot()
const { isBettingen } = useCurrentSite()

// Alva is shown when no other chatbot is being rendered on the page.
const showAlva = computed(
  () => activeChatbot.value === null && !isBettingen.value,
)
</script>
